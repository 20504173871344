<template>
  <div class="innerPageMainContent">
    <b-card>
      <div class="topicGroup">
        <div class="topicItem">
          <h4>參、查核項目</h4>
          <div class="topicSubItemtitle">
            <b-row>
              <b-col cols="6">7.不動產炒作情形（112.2.10之後）</b-col>
              <b-col cols="6" class="text-success">
                檢查結果
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(1)是否散布不實成交價格、市場成交行情、銷售量資訊，影響不動產交易價格。</li>
                </ul>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.SpreadPrice" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.SpreadPrice" unchecked-value="">否(無發現)</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.SpreadPrice" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.SpreadPrice == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_81"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q8-1'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(2)是否與他人通謀或虛偽交易，營造不動產交易活絡之表象。</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.FakeTransaction" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.FakeTransaction" unchecked-value="">否(無發現)</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.FakeTransaction" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.FakeTransaction == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_82"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q8-2'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(3)是否自行、以他人名義或集結多數人違規銷售，且明顯影響市場秩序。</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.IllegalSales" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.IllegalSales" unchecked-value="">否(無發現)</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.IllegalSales" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.IllegalSales == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_83"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q8-3'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(4)其他疑似不動產炒作行為</li>
                  <li><b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.HypeBehaviorContent"></b-form-input></li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.HypeBehavior" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.HypeBehavior" unchecked-value="">否(無發現)</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.HypeBehavior" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.HypeBehavior == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_84"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q8-4'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="remark-group">
        <div class="remark-title">備註</div>
        <div class="remark-body">
          <b-row>
            <b-col cols="12">
              <b-form inline class="alert alert-warning">
                <label>(1)散布方式：</label>
                <b-form-checkbox-group id="radio-group-12" name="flavour-12" v-model="form.SpreadMethodType">
                  <b-form-checkbox value="communication">電子通訊</b-form-checkbox>
                  <b-form-checkbox value="website">網際網路</b-form-checkbox>
                  <b-form-checkbox value="seminar">說明會</b-form-checkbox>
                  <b-form-checkbox value="other">其他</b-form-checkbox><b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.SpreadMethodOther"></b-form-input>
                </b-form-checkbox-group>
              </b-form>
            </b-col>
            <b-col cols="12" class="pb-2">
              <label class="mr-2">(2)散布內容：</label>
              <b-form-textarea id="textarea2" placeholder="請輸入散布內容" v-model="form.SpreadText"
                rows="3" max-rows="6" :formatter="formatter"></b-form-textarea>
            </b-col>
            <b-col cols="12">
              <b-form inline class="alert alert-warning">
                <label>*通謀虛偽交易態樣：</label>
                <b-form-checkbox-group id="radio-group-13"  name="flavour-13" v-model="form.FakeTransactio_Type">
                  <b-form-checkbox value="queue">人頭排隊</b-form-checkbox>
                  <b-form-checkbox value="fakepays">假客戶付訂金</b-form-checkbox>
                  <b-form-checkbox value="falsecontract">簽訂虛假購屋預約單、買賣契約</b-form-checkbox>
                  <b-form-checkbox value="other">其他</b-form-checkbox><b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.FakeTransactionOther"></b-form-input>
                </b-form-checkbox-group>
              </b-form>
            </b-col>
            <b-col cols="12">
              <b-form inline class="alert alert-warning">
                <label>(1)違規銷售管道：</label>
                <b-form-checkbox-group id="radio-group-14"  name="flavour-14" v-model="form.IllegalSalesChannelsType">
                  <b-form-checkbox value="web">網路</b-form-checkbox>
                  <b-form-checkbox value="community">社群</b-form-checkbox>
                  <b-form-checkbox value="specificplace">特定場所</b-form-checkbox>
                  <b-form-checkbox value="other">其他</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form>
            </b-col>
            <b-col cols="12">
              <b-form inline class="alert alert-warning">
                <label>(2)違規銷售主體：</label>
                <b-form-checkbox-group id="radio-group-15"  name="flavour-15" v-model="form.MainIllegalSalesType">
                  <b-form-checkbox value="self">自行</b-form-checkbox>
                  <b-form-checkbox value="someoneelse">以他人名義</b-form-checkbox>
                  <b-form-checkbox value="gather">集結多數人</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form>
            </b-col>
            <b-col cols="12">
              <b-form inline class="alert alert-warning">
                <label>(3)違規銷售態樣：</label>
                <b-form-checkbox-group id="radio-group-16"  name="flavour-16" v-model="form.IllegalSales_type">
                  <b-form-checkbox value="potentialsales">潛銷（未取得建照即進行銷售）</b-form-checkbox>
                  <b-form-checkbox value="unauthorized">未經授權擅自銷售或仲介銷售</b-form-checkbox>
                  <b-form-checkbox value="provideinformation">建立預售屋/新建成屋換約轉售平台/通路或提供資訊</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form>
            </b-col>
            <b-col cols="12" class="pb-2">
              <label class="mr-2">其他疑似炒作行為主體及態樣：</label>
              <b-form-textarea id="textarea2" placeholder="請輸入其他疑似炒作行為主體及態樣" v-model="form.Other4"
                rows="3" max-rows="6" :formatter="formatter"></b-form-textarea>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import UploadImg from '@/components/UploadImg.vue'
export default {
  components: {
    UploadImg
  },
  data() {
    return {
      pageName: '樣品屋/銷售中心合法使用情形',
    };
  },
  watch:{
    "form.SpreadPrice": function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_81" ,value :""})
      }
    },
    "form.FakeTransaction": function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_82" ,value :""})
      }
    },
    "form.IllegalSales": function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_83" ,value :""})
      }
    },
    "form.HypeBehavior": function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_84" ,value :""})
      }
    },

  },
  created() {
    this.userSignCheck();
    this.init();
  },
  computed:{
    form: function(){
        return this.$store.state.form
    },
    dateTime: function(){
        return this.$store.state.dateTime
    },
    OtherText: function(){
        return this.$store.state.OtherText
    },
  },
  mounted() { },
  methods: {
    init(){
      if(this.form.SpreadMethod != "" && this.form.SpreadMethod != null){
        this.$store.commit('increment',{key: "SpreadMethodType" ,value :this.form.SpreadMethod.split('、')})
      }

      if(this.form.FakeTransactionStyle != "" && this.form.FakeTransactionStyle != null){
        this.$store.commit('increment',{key: "FakeTransactio_Type" ,value :this.form.FakeTransactionStyle.split('、')})
      }

      if(this.form.IllegalSalesChannels != "" && this.form.IllegalSalesChannels != null){
        this.$store.commit('increment',{key: "IllegalSalesChannelsType" ,value :this.form.IllegalSalesChannels.split('、')})
      }

      if(this.form.MainIllegalSales != "" && this.form.MainIllegalSales != null){
        this.$store.commit('increment',{key: "MainIllegalSalesType" ,value :this.form.MainIllegalSales.split('、')})
      }

      if(this.form.IllegalSalesStyle != "" && this.form.IllegalSalesStyle != null){
        this.$store.commit('increment',{key: "IllegalSales_type" ,value :this.form.IllegalSalesStyle.split('、')})
      }
    },
    formatter(e){
      return String(e).substring(0,40);
    },
  },
};
</script>
