<template>
  <div class="innerPageMainContent">
    <b-card>
      <div class="topicGroup">
        <div class="topicItem">
          <h4>參、查核項目</h4>
          <div class="topicSubItemtitle">
            <b-row>
              <b-col cols="6">3.廣告內容刊登及資訊揭露情形</b-col>
              <b-col cols="6" class="text-success">
                檢查結果
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(1)廣告上標示建築物坐落地點與實際是否相符。</li>
                </ul>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.AD_buildingLocation" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.AD_buildingLocation" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.AD_buildingLocation" unchecked-value="">無此廣告</b-form-checkbox>
                      <b-form-checkbox value="3" v-model="form.AD_buildingLocation" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.AD_buildingLocation == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_41"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q4-1'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(2)廣告對公有公共設施(如學校、公園、運動場、政府機關等)之表示與廣告當時之客觀狀況或完工後之實際狀況是否相符。</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.AD_PublicUtilities" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.AD_PublicUtilities" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.AD_PublicUtilities" unchecked-value="">無此廣告</b-form-checkbox>
                      <b-form-checkbox value="3" v-model="form.AD_PublicUtilities" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.AD_PublicUtilities == '0' ">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_42"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q4-2'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(3)廣告對交通狀況、時間或空間距離之表示與事實是否相符。</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.AD_TrafficCondition" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.AD_TrafficCondition" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.AD_TrafficCondition" unchecked-value="">無此廣告</b-form-checkbox>
                      <b-form-checkbox value="3" v-model="form.AD_TrafficCondition" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.AD_TrafficCondition == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_43"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q4-3'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(4)廣告照片、圖示內容對不動產法定用途之表示或表徵是否與事實相符。</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.AD_photo_MatchFacts" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.AD_photo_MatchFacts" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.AD_photo_MatchFacts" unchecked-value="">無此廣告</b-form-checkbox>
                      <b-form-checkbox value="3" v-model="form.AD_photo_MatchFacts" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.AD_photo_MatchFacts == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_44"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q4-4'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(5)廣告或報導表示之成交紀錄是否與事實相符</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.AD_TransactionRecord" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.AD_TransactionRecord" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.AD_TransactionRecord" unchecked-value="">無此廣告</b-form-checkbox>
                      <b-form-checkbox value="3" v-model="form.AD_TransactionRecord" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.AD_TransactionRecord == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_45"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q4-5'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(6)廣告是否有引人誤認已取得建照等虛偽不實或引人錯誤情形</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.AD_Is_Hypocrisy" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.AD_Is_Hypocrisy" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.AD_Is_Hypocrisy" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.AD_Is_Hypocrisy == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_46"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q4-6'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(7)銷售時是否揭露全區各戶之主建物、附屬建物與共有部分之面積及共有部分之分攤比率</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col>
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.Supply_Proportion" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.Supply_Proportion" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.Supply_Proportion" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Supply_Proportion == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_47"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q4-7'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(8)銷售時是否提供基地位置圖、地盤圖(坐落基地之地籍圖)、各層平面圖及停車空間平面圖 </li>
                </ul>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.Supply_Floorplan" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.Supply_Floorplan" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.Supply_Floorplan" unchecked-value="">待查明</b-form-checkbox>
                      <b-form-checkbox value="3" v-model="form.Supply_Floorplan" unchecked-value="">未開始廣告銷售</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Supply_Floorplan == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_48"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q4-8'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(9)銷售時是否提供預售屋買賣契約書（應含共有部分之項目、面積或比例分攤之計算方式） </li>
                </ul>
              </b-col>
              <b-col  cols="6">
                <b-row>
                  <b-col>
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.Supply_SalesContract" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.Supply_SalesContract" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.Supply_SalesContract" unchecked-value="">待查明</b-form-checkbox>
                      <b-form-checkbox value="3" v-model="form.Supply_SalesContract" unchecked-value="">未開始廣告銷售</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Supply_SalesContract == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_49"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q4-9'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(10)代銷業之廣告內容是否註明經紀業名稱*建商製作廣告免註明，請勾選「建商製作」 </li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.Indicate_Buildingname" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.Indicate_Buildingname" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.Indicate_Buildingname" unchecked-value="">待查明</b-form-checkbox>
                      <b-form-checkbox value="3" v-model="form.Indicate_Buildingname" unchecked-value="">建商製作</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Indicate_Buildingname == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_410"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q4-10'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="remark-group">
        <div class="remark-title">備註</div>
        <div class="remark-body">
          <b-row>
            <b-col cols="12">
              <b-form inline class="alert alert-warning">
                <label>查核廣告態樣：</label>
                <b-form-checkbox-group id="radio-group-11" v-model="form.ADstyle" name="flavour-11">
                  <b-form-checkbox value="DM">DM</b-form-checkbox>
                  <b-form-checkbox value="video">影片</b-form-checkbox>
                  <b-form-checkbox value="wall">牆面</b-form-checkbox>
                  <b-form-checkbox value="model">模型屋</b-form-checkbox>
                  <b-form-checkbox value="sample">樣品屋</b-form-checkbox>
                  <b-form-checkbox value="others">其他</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form>
            </b-col>
            <b-col cols="12" class="pb-2">
              <label class="mr-2">廣告不實情形：</label>
              <b-form-textarea id="textarea" v-model="form.Misleading_AD" placeholder="請輸入廣告不實情形" rows="3"
                max-rows="6" :formatter="formatter"></b-form-textarea>
            </b-col>
            <b-col>
              <b-form inline>
                <b-form-checkbox value="1" v-model="form.Nonresidential_sales" unchecked-value="">是</b-form-checkbox>
                <b-form-checkbox value="0" v-model="form.Nonresidential_sales" unchecked-value="">否</b-form-checkbox>
                屬銷售標的非住宅（如辦公室）卻以住宅型式預售（廣告不實、違反建築法使用管制）
              </b-form>
            </b-col>
            <b-col cols="12" class="pb-2">
              <label class="mr-2">廣告或報導成交情形：</label>
              <b-form-textarea id="textarea2" v-model="form.TransactionStatus" placeholder="請輸入廣告成交情形"
                rows="3" max-rows="6" :formatter="formatter"></b-form-textarea>
            </b-col>
            <b-col cols="12" class="pb-2">
              <label class="mr-2">廣告引人誤認情形：</label>
              <b-form-textarea id="textarea3" v-model="form.Misleading" placeholder="請輸入廣告引人誤認情形" rows="3"
                max-rows="6" :formatter="formatter"></b-form-textarea>
            </b-col>
          </b-row>
          <small>*查核結果違規者，應敘明個案違規情形並蒐集相關事證。</small>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import UploadImg from '@/components/UploadImg.vue'
export default {
  components: {
    UploadImg
  },
  data() {
    return {
      pageName: '廣告內容刊登及資訊揭露情形',
    };
  },
  watch:{
    "form.AD_buildingLocation": function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_41" ,value :""});
      }
    },
    "form.AD_PublicUtilities": function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_42" ,value :""});
      }
    },
    "form.AD_TrafficCondition": function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_43" ,value :""});
      }
    },
    "form.AD_photo_MatchFacts": function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_44" ,value :""});
      }
    },
    "form.AD_TransactionRecord": function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_45" ,value :""});
      }
    },
    "form.AD_Is_Hypocrisy": function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_46" ,value :""});
      }
    },
    "form.Supply_Proportion": function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_47" ,value :""});
      }
    },
    "form.Supply_Floorplan": function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_48" ,value :""});
      }
    },
    "form.Supply_SalesContract": function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_49" ,value :""});
      }
    },
    "form.Indicate_Buildingname": function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_410" ,value :""});
      }
    },
  },
  created() {
    this.userSignCheck();
    if(this.form.AD_style != null && this.form.AD_style != ""){
      this.$store.commit('increment',{key: "ADstyle" ,value :this.form.AD_style.split('、')});
    } 
  },
  computed:{
    form: function(){
        return this.$store.state.form
    },
    dateTime: function(){
        return this.$store.state.dateTime
    },
    OtherText: function(){
        return this.$store.state.OtherText
    },
  },
  mounted() { },
  methods: {
    formatter(e){
      return String(e).substring(0,100);
    },  
  },
}
</script>
