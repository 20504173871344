<template>
  <div class="innerPageMainContent">
    <b-card>
      <div class="topicGroup">
        <div class="topicItem">
          <h4>參、查核項目</h4>
          <div class="topicSubItemtitle">
            <b-row>
              <b-col cols="6">8.樣品屋/銷售中心合法使用情形</b-col>
              <b-col cols="6" class="text-success">
                檢查結果
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(1)樣品屋是否有申請建築執照、建築許可或核准使用</li>
                </ul>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.Sample_buildingPermit" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.Sample_buildingPermit" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.Sample_buildingPermit" unchecked-value="">免申請</b-form-checkbox>
                      <b-form-checkbox value="3" v-model="form.Sample_buildingPermit" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Sample_buildingPermit == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_91"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q9-1'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(2)銷售中心是否申請建築執照、建築許可或核准使用</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.Selling_buildingPermit" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.Selling_buildingPermit" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.Selling_buildingPermit" unchecked-value="">免申請</b-form-checkbox>
                      <b-form-checkbox value="3" v-model="form.Selling_buildingPermit" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Selling_buildingPermit == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_92"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q9-2'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="remark-group">
          <div class="remark-title">備註</div>
          <div class="remark-body">
            <div class="alert alert-warning">
              <b-form-group label-cols-lg="2" label="樣品屋" label-size="lg" label-class="font-weight-bold pt-0"
                class="mb-0">
                <b-form-group label="建築許可/許可核准日期字號：" label-for="nested-street" label-cols-sm="4"
                  label-align-sm="right">
                  <b-form-input id="nested-street" v-model="form.SampleHouse_BuildLicense" :formatter="formatter">
                  </b-form-input>
                </b-form-group>

                <b-form-group label="合法使用法源：" label-for="nested-city" label-cols-sm="4" label-align-sm="right">
                  <b-form-input id="nested-city" v-model="form.SampleHouse_Other" :formatter="formatter"></b-form-input>
                </b-form-group>
                <label class="mr-2">註：如個案情形，未設樣品屋、無須申請許可，請勾選”符合”。</label>
              </b-form-group>
            </div>
            <div class="alert alert-success">
              <b-form-group label-cols-lg="2" label="銷售中心" label-size="lg" label-class="font-weight-bold pt-0"
                class="mb-0">
                <b-form-group label="建築許可/許可核准日期字號：" label-for="nested-street" label-cols-sm="4"
                  label-align-sm="right">
                  <b-form-input id="nested-street" v-model="form.Selling_BuildLicense" :formatter="formatter">
                  </b-form-input>
                </b-form-group>

                <b-form-group label="合法使用法源：" label-for="nested-city" label-cols-sm="4" label-align-sm="right">
                  <b-form-input id="nested-city"  v-model="form.Selling_Other" :formatter="formatter"></b-form-input>
                </b-form-group>
                <label class="mr-2">註：如個案情形，未設銷售中心、無須申請許可，或使用情形無違規情事者，請勾選”符合”。</label>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="topicItem">
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                9.查核過程中受檢對象是否規避、妨礙或拒絕
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.Is_Avoid" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.Is_Avoid" unchecked-value="">否</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Is_Avoid == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_93"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q9-3'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="1">
              <b-col>
                10.其他：
              </b-col>
              <b-col>
                <b-form-textarea id="textarea" v-model="form.Other" placeholder="請輸入其他" rows="3"
                  max-rows="6"></b-form-textarea>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import UploadImg from '@/components/UploadImg.vue'
export default {
  components: {
    UploadImg
  },
  data() {
    return {
      pageName: '樣品屋及銷售中心之使用許可情形/其他',
    };
  },
  watch:{
    "form.Sample_buildingPermit": function(val){
      if(val != "0"){
        this.$store.commit('incrementOtherText', {key: 'other_91', value: ''});
      }
    },
    "form.Selling_buildingPermit": function(val){
      if(val != "0"){
        this.$store.commit('incrementOtherText', {key: 'other_92', value: ''});
      }
    },
    "form.Is_Avoid": function(val){
      if(val != "0"){
        this.$store.commit('incrementOtherText', {key: 'other_93', value: ''});
      }
    },
  },
  created() {
    this.userSignCheck();
  },
  computed:{
    form: function(){
        return this.$store.state.form
    },
    dateTime: function(){
        return this.$store.state.dateTime
    },
    OtherText: function(){
        return this.$store.state.OtherText
    },
  },
  mounted() { },
  methods: {
    formatter(e){
      return String(e).substring(0,40);
    },
  },
};
</script>
