<template>
  <div class="innerPageMainContent" >
  <b-card style="min-height: 500px;">
    <div class="topicGroup">
      <div class="topicItem">
        <h4>參、查核項目</h4>
        <div class="topicSubItemtitle">
          <b-row>
            <b-col cols="6">1.建造執照取得情形</b-col>
            <b-col cols="6" class="text-success">
              檢查結果
            </b-col>
          </b-row>
        </div>
        <div class="topicSubItemList">
          <b-row align-v="center" cols="2">
            <b-col>
              <ul>
                <li>(1)是否已取得建造執照</li>
              </ul>
            </b-col>
            <b-col>
              <b-row>
                <b-col >
                  <b-form inline>
                    <b-form-checkbox value="1" v-model="form.Has_License" unchecked-value="">是</b-form-checkbox>
                    <b-form-checkbox value="0" v-model="form.Has_License" unchecked-value="">否</b-form-checkbox>
                  </b-form>
                </b-col>
              </b-row>
              <b-row v-if="form.Has_License == '0'">
                <b-col>
                  <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_21"></b-form-input>
                </b-col>
                <b-col cols="2">
                  <UploadImg :topic="'Q2-1'"></UploadImg>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <div class="topicSubItemList">
          <b-row align-v="center" cols="2">
            <b-col>
              <ul>
                <li>(2)是否取得建造執照才開始廣告銷售（含購屋預約單）</li>
                <li class="ml-lg-4">*請查核已簽訂之預約單5份<span class="text-danger">(首次簽訂1份、近期簽訂4份)</span></li>
              </ul>
            </b-col>
            <b-col>
              <b-row>
                <b-col >
                  <b-form inline>
                      <b-form-checkbox value="1" v-model="form.ObtainLicense" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.ObtainLicense" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.ObtainLicense" unchecked-value="">待查明</b-form-checkbox>
                      <b-form-checkbox value="3" v-model="form.ObtainLicense" unchecked-value="">未開始廣告銷售</b-form-checkbox>
                  </b-form>
                </b-col>
              </b-row>
              <b-row v-if="form.ObtainLicense == '0'">
                <b-col>
                  <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_22"></b-form-input>
                </b-col>
                <b-col cols="2">
                  <UploadImg :topic="'Q2-2'"></UploadImg>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div style="height: 120px;"></div>
    <div class="remark-group">
      <div class="remark-title">備註</div>
      <div class="remark-body">
        <b-row>
          <b-col cols="auto" class="pb-2">
            <b-form inline style="z-index: 9999;">
              <label class="mr-2">發照日期:</label>
              <!-- <b-form-datepicker v-model="form.ObtainLicenseDate" id="example-datepicker" class="mb-2" dropup></b-form-datepicker> -->
              <b-input-group class="mb-2 dateInput">
                <b-form-input
                  v-model="dateTime.ObtainLicenseDate"
                  type="text"
                  placeholder="YYYMMDD，例如:0991231"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="form.ObtainLicenseDate"
                    button-only
                    right
                    locale="zh-TW"
                    aria-controls="example-input"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </b-col>
          <b-col cols="auto" class="pb-2">
            <b-form inline>
              <label class="mr-2">建造執照號碼：</label>
              <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.LicenseNumber" maxlength="40"></b-form-input>
            </b-form>
          </b-col>
          <b-col cols="auto" class="pb-2">
            <b-form inline>
              <label class="mr-2">總銷戶數：</label>
              <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.TotalSales"></b-form-input>
              <label class="mr-2">戶</label>
            </b-form>
          </b-col>
          <b-col cols="auto" class="pb-2">
            <small>註：廣告係銷售行為一環，如為已開始廣告僅未售出任1張紅單，即屬廣告銷售行為，勿選「未開始廣告銷售」。</small>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-card>
</div>
</template>
<script>
import UploadImg from '@/components/UploadImg.vue'
export default {
  name: "page2",
  components: {
    UploadImg
  },
  data() {
    return {
    };
  },
  computed:{
    form: function(){
        return this.$store.state.form
    },
    dateTime: function(){
        return this.$store.state.dateTime
    },
    OtherText: function(){
        return this.$store.state.OtherText
    },
  },
  watch:{
    "form.Has_License":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_21" ,value :""})
      }
    },
    "form.ObtainLicense":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_22" ,value :""})
      }
    },
    "form.ObtainLicenseDate":function(val){
      //console.log("ObtainLicenseDate",val,this.date2str2(new Date(val)))
      if(this.date2str2(new Date(val)) != this.dateTime.ObtainLicenseDate){
        this.$store.commit('incrementDateTime',{key: "ObtainLicenseDate" ,value :!this.IsNullEmpty(val) ? this.date2str2(new Date(val)):""})
      }
    },
    "dateTime.ObtainLicenseDate":function(val){
      if(val.length == 7 && val != this.date2str2(new Date(this.form.ObtainLicenseDate)) ){
        let date = this.str2dateStr(val)
        if(this.IsDateValid(date)){
          this.$store.commit('increment',{key: "ObtainLicenseDate" ,value :date})
          //this.$store.commit('incrementDateTime',{key: "FoundDate" ,value :date})
        }
        else{
          alert("日期格式錯誤")
        }
      }
      if(val == "" || val == null){
        this.$store.commit('increment',{key: "ObtainLicenseDate" ,value :""})
      }
    }
  },
  created() {
    this.userSignCheck();
  },
  mounted() { },
  methods: {
    maxLength(val){
      console.log("val",val)
    }
  },
};
</script>
<style scoped>

</style>
