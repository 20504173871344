<template>
  <div class="innerPageMainContent">
    <b-card>
      <div class="topicGroup">
        <div class="topicItem">
          <h4>參、查核項目</h4>
          <div class="topicSubItemtitle">
            <b-row>
              <b-col cols="6">6.代銷業及銷售人員執業情形</b-col>
              <b-col cols="6" class="text-success">
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.SelfSale" unchecked-value="">建商自售無代銷</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(1)許可文件、同業公會會員證書、經紀人證書（影本）是否揭示於營業處所明顯之處</li>
                </ul>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox :disabled="disabled" value="1" v-model="form.Is_Reveal" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="0" v-model="form.Is_Reveal" unchecked-value="">否</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Is_Reveal == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_71"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q7-1'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(2)現場銷售人員是否均有經紀人員資格(請填名冊)*請檢視是否具備有效經紀人員證書(明)</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox :disabled="disabled" value="1" v-model="form.All_IsBroker" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="0" v-model="form.All_IsBroker" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="2" v-model="form.All_IsBroker" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.All_IsBroker == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_72"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q7-2'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(3)本建案是否有置專任不動產經紀人(請填名冊)
                    *請輔以簽章之文件確認，有置而未備查請勾選「是」（非本項缺失），並另列經紀人未到職備查缺失。
                    *建案總銷金額未達6億者請勾選「免置經紀人」。
                    </li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox :disabled="disabled" value="1" v-model="form.Full_Broker" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="0" v-model="form.Full_Broker" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="2" v-model="form.Full_Broker" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Full_Broker == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_73"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q7-3'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(4)預售屋買賣契約書是否指派經紀人簽章
                    *請查核已簽約之預售屋買賣契約書
                    </li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox :disabled="disabled" value="1" v-model="form.Sales_Brokersing" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="0" v-model="form.Sales_Brokersing" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="2" v-model="form.Sales_Brokersing" unchecked-value="">待查明</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="3" v-model="form.Sales_Brokersing" unchecked-value="">免置經紀人</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Sales_Brokersing == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_74"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q7-4'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(5)銷售中心是否已完成分設非常態營業處所備查</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox :disabled="disabled" value="1" v-model="form.Separate_CtR" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="0" v-model="form.Separate_CtR" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="2" v-model="form.Separate_CtR" unchecked-value="">無銷售中心</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Separate_CtR == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_75"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q7-5'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(6)本建案委託代銷契約是否已完成備查</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox :disabled="disabled" value="1" v-model="form.Appointment_CtR" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="0" v-model="form.Appointment_CtR" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="2" v-model="form.Appointment_CtR" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Appointment_CtR == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_76"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q7-6'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="remark-group">
        <div class="remark-title">備註</div>
        <div class="remark-body">
          <b-row>
            <b-col cols="12" class="pb-2">
              <b-form inline>
                <label>代銷契約備查日：</label>
                <b-input-group class="mb-2 dateInput">
                  <b-form-input
                    v-model="dateTime.DForSale_Date"
                    type="text"
                    placeholder="YYYMMDD，例如:0991231"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="form.DForSale_Date"
                      button-only
                      right
                      locale="zh-TW"
                      aria-controls="example-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form>
            </b-col>
            <!-- <b-col cols="12" class="pb-2">
              <b-form inline>
                <label>委託代銷契約備查文號：</label>
                <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.AgencyContract"></b-form-input>
              </b-form>
            </b-col> -->
            <b-col cols="12" class="pb-2">
              <b-form inline>
                <label class="mr-sm-2">*委託代銷期間：</label>
                <label class="mr-sm-2">自</label>
                <b-input-group class="mb-2 dateInput">
                  <b-form-input
                    v-model="dateTime.ConsignmentDate_begin"
                    type="text"
                    placeholder="YYYMMDD，例如:0991231"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="form.ConsignmentDate_begin"
                      button-only
                      right
                      locale="zh-TW"
                      aria-controls="example-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
                <label class="mx-sm-2">至</label>
                  <b-input-group class="mb-2 dateInput">
                    <b-form-input
                      v-model="dateTime.ConsignmentDate_until"
                      type="text"
                      placeholder="YYYMMDD，例如:0991231"
                      autocomplete="off"
                      :disabled="state"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="form.ConsignmentDate_until"
                        button-only
                        :disabled="state"
                        right
                        locale="zh-TW"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                <label class="mr-sm-2">日</label>
                <label class="mr-sm-2">或至</label>
                <b-form-input class="mb-0" v-model="form.ConsignmentDate_untilInput" :readonly="Datekeyin" :formatter="formatter_1"></b-form-input>
              </b-form>
            </b-col>
            <b-col cols="12" class="pb-2">
              <b-form inline>
                <label class="mr-2">*委託銷售總金額：</label>
                <b-form-input class="mb-0" v-model="form.AmountTotal"></b-form-input>
                <label class="mr-2">萬元</label>
              </b-form>
            </b-col>
            <!-- <b-col cols="12" class="pb-2">
              <b-form inline>
                <label>*專任經紀人姓名及證號：</label>
                <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.FulltimeBroker"></b-form-input>
              </b-form>
            </b-col> -->
          </b-row>
          <!-- <small>註：請業者提供名現場銷售人員姓名及證號。</small> -->
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import UploadImg from '@/components/UploadImg.vue'
export default {
  components: {
    UploadImg
  },
  data() {
    return {
      pageName: '代銷業及銷售人員執業情形',
      state: false,
      Datekeyin:false,
      options: [
        { value: '0', text: '0' },
        { value: '1', text: '1' },
        { value: '2', text: '2' },
        { value: '3', text: '3' },
        { value: '4', text: '4' },
        { value: '5', text: '5' },
        { value: '6', text: '6' },
        { value: '7', text: '7' },
        { value: '8', text: '8' },
        { value: '9', text: '9' },
      ],
      list:["form.Appointment_CtR"],
      selected: null,
      disabled: false,
      selections:["Is_Reveal","All_IsBroker","Full_Broker","Sales_Brokersing","Separate_CtR","Appointment_CtR"],
    };
  },
  computed:{
    form: function(){
        return this.$store.state.form
    },
    dateTime: function(){
        return this.$store.state.dateTime
    },
    OtherText: function(){
        return this.$store.state.OtherText
    },
  },
  watch:{
    "form.SelfSale":function(val){
      if(val == 1){
        this.disabled = true;
        this.selections.forEach((item)=>{
          this.$store.commit('increment',{key: item ,value :""})
        })
      }
      else{
        this.disabled = false;
      }
    },
    "form.Is_Reveal":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_71" ,value :""})
      }
    },
    "form.All_IsBroker":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_72" ,value :""})
      }
    },
    "form.Full_Broker":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_73" ,value :""})
      }
    },
    "form.Sales_Brokersing":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_74" ,value :""})
      }
    },
    "form.Separate_CtR":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_75" ,value :""})
      }
    },
    "form.Appointment_CtR":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_76" ,value :""})
      }
    },
    'form.ConsignmentDate_untilInput'(val){
      if(val == null || val == "") this.state = false;
      else this.state = true;
    },
    "form.DForSale_Date":function(val){
      //console.log("ObtainLicenseDate",val,this.date2str2(new Date(val)))
      if(this.date2str2(new Date(val)) != this.dateTime.DForSale_Date){
        this.$store.commit('incrementDateTime',{key: "DForSale_Date" ,value :!this.IsNullEmpty(val) ? this.date2str2(new Date(val)):""})
      }
    },
    "dateTime.DForSale_Date":function(val){
      if(val.length == 7 && val != this.date2str2(new Date(this.form.DForSale_Date)) ){
        let date = this.str2dateStr(val)
        if(this.IsDateValid(date)){
          this.$store.commit('increment',{key: "DForSale_Date" ,value :date})
          //this.$store.commit('incrementDateTime',{key: "FoundDate" ,value :date})
        }
        else{
          alert("日期格式錯誤")
        }
      }
      if(val == "" || val == null){
        this.$store.commit('increment',{key: "DForSale_Date" ,value :""})
      }
    },
    "form.ConsignmentDate_begin":function(val){
      //console.log("ObtainLicenseDate",val,this.date2str2(new Date(val)))
      if(this.date2str2(new Date(val)) != this.dateTime.ConsignmentDate_begin){
        this.$store.commit('incrementDateTime',{key: "ConsignmentDate_begin" ,value :!this.IsNullEmpty(val) ? this.date2str2(new Date(val)):""})
      }
    },
    "dateTime.ConsignmentDate_begin":function(val){
      if(val.length == 7 && val != this.date2str2(new Date(this.form.ConsignmentDate_begin)) ){
        let date = this.str2dateStr(val)
        if(this.IsDateValid(date)){
          this.$store.commit('increment',{key: "ConsignmentDate_begin" ,value :date})
          //this.$store.commit('incrementDateTime',{key: "FoundDate" ,value :date})
        }
        else{
          alert("日期格式錯誤")
        }
      }
      if(val == "" || val == null){
        this.$store.commit('increment',{key: "ConsignmentDate_begin" ,value :""})
      }
    },
    "form.ConsignmentDate_until":function(val){
      //console.log("ObtainLicenseDate",val,this.date2str2(new Date(val)))
      if(val == null || val == "") this.Datekeyin = false;
      else this.Datekeyin = true;
      
      if(this.date2str2(new Date(val)) != this.dateTime.ConsignmentDate_until){
        this.$store.commit('incrementDateTime',{key: "ConsignmentDate_until" ,value :!this.IsNullEmpty(val) ?this.date2str2(new Date(val)):""})
      }
    },
    "dateTime.ConsignmentDate_until":function(val){
      if(val.length == 7 && val != this.date2str2(new Date(this.form.ConsignmentDate_until)) ){
        let date = this.str2dateStr(val)
        if(this.IsDateValid(date)){
          this.$store.commit('increment',{key: "ConsignmentDate_until" ,value :date})
          //this.$store.commit('incrementDateTime',{key: "FoundDate" ,value :date})
        }
        else{
          alert("日期格式錯誤")
        }
      }
      if(val == null || val == ""){
        this.$store.commit('increment',{key: "ConsignmentDate_until" ,value :""})
      }
    }
  },
  created() {
    this.userSignCheck();
    if(this.form.SelfSale == 1){
      this.disabled = true;
    }
    if(this.form.ConsignmentDate_until != null && this.form.ConsignmentDate_until != ""){
      this.Datekeyin = true;
    }else{
      this.Datekeyin = false;
    }
    if(this.form.ConsignmentDate_untilInput != null && this.form.ConsignmentDate_untilInput != ""){
      this.state = true;
    }else {
      this.state = false;
    }
  },
  mounted() { },
  methods: {
    formatter(e){
      return String(e).substring(0,40);
    },
    formatter_1(e){
      return String(e).substring(0,15);
    },
  },
};
</script>
