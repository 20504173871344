<template>
  <div>
    <div>
        <b-navbar toggleable="lg" type="dark" variant="dark" class="justify-content-center">
          <b-navbar-brand @click="goHome">臺中市預售屋銷售建案稽查紀錄系統</b-navbar-brand>

          <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->

          <!-- <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item href="#">Link</b-nav-item>
              <b-nav-item href="#">Link</b-nav-item>
            </b-navbar-nav>
          </b-collapse> -->
        </b-navbar>

    </div>
    <router-view />
    <div class="footer">
      <b-container>
        <b-row align-h="center" align-v="center" cols="1" cols-sm="1" cols-md="2" cols-lg="3" cols-xl="3">
          <b-col>
            <b-img
                  :src="require('@/assets/logo.png')"
                  fluid
                  alt="台中市政府地政局"
                ></b-img>
          </b-col>
        </b-row>

      </b-container>
    </div>
  </div>
</template>

<script>
  export default {
    name: "bar",
    data() {
      return {};
    },
    watch: {},
    mounted() {},
    methods: {
      goHome(){
        this.$router.push({ path: "/Home" });
      }
    },
  };
</script>
