<template>
  <div class="innerPageMainContent">
    <b-card>
      <div class="topicGroup">
        <div class="topicItem">
          <h4>參、查核項目</h4>
          <div class="topicSubItemtitle">
            <b-row>
              <b-col cols="6">2.預售屋銷售資訊備查及購屋預約單（紅單）銷售情形</b-col>
              <b-col cols="6" class="text-success">
                檢查結果
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(1)預售屋資訊及買賣契約是否未報備查即銷售</li>
                </ul>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.WithoutInspection" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.WithoutInspection" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.WithoutInspection" unchecked-value="">免報備</b-form-checkbox>
                      <b-form-checkbox value="3" v-model="form.WithoutInspection" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.WithoutInspection == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_31"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q3-1'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(2)有無使用購屋預約單銷售預售屋</li>
                  <li class="ml-lg-4">*如<span class="text-danger">有</span>請備註簽訂紅單資訊<span
                    class="text-danger">（有紅單格式即屬之，不論是否售出紅單）</span></li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.WrittenDeed" unchecked-value="">有</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.WrittenDeed" unchecked-value="">無</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.WrittenDeed" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.WrittenDeed == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_32"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q3-2'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(3)收取預約金（定金/訂金/保留金）是否以書面契據（預約單）確立買賣戶別、面積、停車位編號及價金</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.Disadvantages" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.Disadvantages" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.Disadvantages" unchecked-value="">無使用紅單</b-form-checkbox>
                      <b-form-checkbox value="3" v-model="form.Disadvantages" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Disadvantages == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_33"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q3-3'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(4)預約單是否約定賣方得保留出售或簽約權利或其他不利於買受人之事項</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.NotforResale" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.NotforResale" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.NotforResale" unchecked-value="">無使用紅單</b-form-checkbox>
                      <b-form-checkbox value="3" v-model="form.NotforResale" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.NotforResale == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_34"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q3-4'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(5)預約金收據是否由經紀人簽章</li>
                  <li class="ml-lg-4">*代銷尚未售出首張紅單請勾選「未簽訂紅單」</li>
                  <li class="ml-lg-4">*建商自售者免由經紀人簽章，請勾選「免簽章」</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.BrokerSigning" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.BrokerSigning" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.BrokerSigning" unchecked-value="">待查明</b-form-checkbox>
                      <b-form-checkbox value="3" v-model="form.BrokerSigning" unchecked-value="">無使用紅單</b-form-checkbox>
                      <b-form-checkbox value="4" v-model="form.BrokerSigning" unchecked-value="">未簽訂紅單</b-form-checkbox>
                      <b-form-checkbox value="5" v-model="form.BrokerSigning" unchecked-value="">免簽章</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.BrokerSigning == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_35"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q3-5'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(6)是否要求消費者繳納預約金、定金或保留金後始提供預售屋買賣契約書審閱。</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form inline>
                        <b-form-checkbox value="1" v-model="form.Pay_before_View" unchecked-value="">有</b-form-checkbox>
                        <b-form-checkbox value="0" v-model="form.Pay_before_View" unchecked-value="">無</b-form-checkbox>
                        <b-form-checkbox value="2" v-model="form.Pay_before_View" unchecked-value="">待查明</b-form-checkbox>
                      </b-form>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Pay_before_View == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_36"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q3-6'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(7)是否同意或協助買受人將預售屋轉售與第三人。</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.topic37" unchecked-value="">有</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.topic37" unchecked-value="">無</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.topic37" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.topic37 == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_37"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q3-7'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="remark-group">
        <div class="remark-title">備註</div>
        <div class="remark-body">
          <b-row>
            <b-col cols="auto" class="pb-2">
              <b-form inline>
                <label class="mr-2">報備日期:</label>
                <b-input-group class="mb-2 dateInput">
                  <b-form-input
                    v-model="dateTime.ReportDate"
                    type="text"
                    placeholder="YYYMMDD，例如:0991231"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="form.ReportDate"
                      button-only
                      right
                      locale="zh-TW"
                      aria-controls="example-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form>
            </b-col>
            <b-col cols="auto" class="pb-2">
              <b-form inline>
                <label class="mr-2">備查日期:</label>
                <b-input-group class="mb-2 dateInput">
                  <b-form-input
                    v-model="dateTime.CensorDate"
                    type="text"
                    placeholder="YYYY-MM-DD，例如:0991231"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="form.CensorDate"
                      button-only
                      right
                      locale="zh-TW"
                      aria-controls="example-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form>
            </b-col>
            <b-col cols="auto" class="pb-2">
              <b-form inline>
                <label class="mr-2">簽訂紅單戶數：</label>
                <b-form-input class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.Signed_num"></b-form-input>
                <label class="mr-2">戶</label>
              </b-form>
            </b-col>
            <b-col cols="auto" class="pb-2">
              <b-form inline>
                <label class="mr-2">每戶預收金額約：</label>
                <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.Advance_Payment"></b-form-input>
                <label class="mr-2">萬</label>
              </b-form>
            </b-col>
            <b-col cols="auto" class="pb-2">
              <b-form inline>
                <label class="mr-2">首張預約單簽約日：</label>
                <b-input-group class="mb-2 dateInput">
                  <b-form-input
                    v-model="dateTime.SigningDate"
                    type="text"
                    placeholder="YYYY-MM-DD，例如:0991231"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="form.SigningDate"
                      button-only
                      right
                      locale="zh-TW"
                      aria-controls="example-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form>
            </b-col>
            <b-col cols="12" class="pb-2">
              <label class="mr-2">補充說明：</label>
              <b-form-textarea id="textarea" v-model="form.Directions" placeholder="請輸入補充說明" rows="3"
                max-rows="6" :formatter="formatter"></b-form-textarea>
            </b-col>
          </b-row>
          <div class="topicSubItemList"> 
          <b-row>
            <b-col>
              <b-form inline>
                <b-form-checkbox value="1" v-model="form.Has_Transfer" unchecked-value="">有</b-form-checkbox>
                <b-form-checkbox value="0" v-model="form.Has_Transfer" unchecked-value="">無</b-form-checkbox>
                發現預約單轉讓與第三人情事。
              </b-form>
            </b-col>
          </b-row></div>
          <small>*請查核已簽約之預約單<br>*第(6)項查核結果違規者，應敘明個案違規情形並蒐集相關事證。</small>
        </div>
      </div>


    </b-card>
  </div>
</template>
<script>
import UploadImg from '@/components/UploadImg.vue'
export default {
  components: {
    UploadImg
  },
  data() {
    return {
      pageName: '預售屋銷售備查及預約單使用情形',
    };
  },
  computed:{
    form: function(){
        return this.$store.state.form
    },
    dateTime: function(){
        return this.$store.state.dateTime
    },
    OtherText: function(){
        return this.$store.state.OtherText
    },
  },
  watch:{
    "form.WithoutInspection":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_31" ,value :""})
      }
    },
    "form.WrittenDeed":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_32" ,value :""})
      }
    },
    "form.Disadvantages":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_33" ,value :""})
      }
    },
    "form.NotforResale":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_34" ,value :""})
      }
    },
    "form.BrokerSigning":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_35" ,value :""})
      }
    },
    "form.Pay_before_View":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_36" ,value :""})
      }
    },
    "form.topic37":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_37" ,value :""})
      }
    },
    "dateTime.ReportDate":function(val){
      if(val.length == 7 && val != this.date2str2(new Date(this.form.ReportDate)) ){
        let date = this.str2dateStr(val)
        if(this.IsDateValid(date)){
          this.$store.commit('increment',{key: "ReportDate" ,value :date})
          //this.$store.commit('incrementDateTime',{key: "FoundDate" ,value :date})
        }
        else{
          alert("日期格式錯誤")
        }
      }
      if(val == "" || val == null){
        this.$store.commit('increment',{key: "ReportDate" ,value :""})
      }
    },
    "form.ReportDate":function(val){
      if(this.date2str2(new Date(val)) != this.dateTime.ReportDate){
        this.$store.commit('incrementDateTime',{key: "ReportDate" ,value :!this.IsNullEmpty(val) ? this.date2str2(new Date(val)):""})
      }
    },
    "dateTime.CensorDate":function(val){
      if(val.length == 7 && val != this.date2str2(new Date(this.form.CensorDate)) ){
        let date = this.str2dateStr(val)
        if(this.IsDateValid(date)){
          this.$store.commit('increment',{key: "CensorDate" ,value :date})
          //this.$store.commit('incrementDateTime',{key: "FoundDate" ,value :date})
        }
        else{
          alert("日期格式錯誤")
        }
      }
      if(val == "" || val == null){
        this.$store.commit('increment',{key: "CensorDate" ,value :""})
      }
    },
    "form.CensorDate":function(val){
      if(this.date2str2(new Date(val)) != this.dateTime.CensorDate){
        this.$store.commit('incrementDateTime',{key: "CensorDate" ,value :!this.IsNullEmpty(val) ? this.date2str2(new Date(val)):""})
      }
    },
    "dateTime.SigningDate":function(val){
      if(val.length == 7 && val != this.date2str2(new Date(this.form.SigningDate)) ){
        let date = this.str2dateStr(val)
        if(this.IsDateValid(date)){
          this.$store.commit('increment',{key: "SigningDate" ,value :date})
          //this.$store.commit('incrementDateTime',{key: "FoundDate" ,value :date})
        }
        else{
          alert("日期格式錯誤")
        }
      }
      if(val == "" || val == null){
        this.$store.commit('increment',{key: "SigningDate" ,value :""})
      }
    },
    "form.SigningDate":function(val){
      if(this.date2str2(new Date(val)) != this.dateTime.SigningDate){
        this.$store.commit('incrementDateTime',{key: "SigningDate" ,value :!this.IsNullEmpty(val) ? this.date2str2(new Date(val)):""})
      }
    }
  },
  created() {
    this.userSignCheck();
  },
  mounted() { },
  methods: {
    formatter(e){
      return String(e).substring(0,100);
    },
  },
};
</script>
