<template>
  <div class="innerPageMainContent">
    <b-card>
      <div class="topicGroup">
        <div class="topicItem">
          <h4>參、查核項目</h4>
          <div class="topicSubItemtitle">
            <b-row>
              <b-col cols="6">4.預售屋買賣契約書是否符合預售屋買賣定型化契約應記載及不得記載事項</b-col>
              <b-col cols="6" class="text-success">
                <b-form inline>
                  <b-form-checkbox value="0" v-model="form.Is_recorded" unchecked-value="">無契約</b-form-checkbox>
                  <b-form-checkbox value="1" v-model="form.Is_recorded" unchecked-value="">全案不適用規範</b-form-checkbox>
                    </b-form>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(1)是否提供契約審閱期至少5日。</li>
                </ul>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col >
                    <b-form inline >
                      <b-form-checkbox :disabled="disabled" value="1" v-model="form.Supply_Review" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="0" v-model="form.Supply_Review" unchecked-value="" >否</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Supply_Review == '0' ">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_51"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q5-1'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(2)是否列明停車位長寬高、停車空間面積(含車位、車道及其他必要空間)及「停車空間面積占共有部分總面積之比例」</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox :disabled="disabled" value="1" v-model="form.Listed_Area" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="0" v-model="form.Listed_Area" unchecked-value="">否</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Listed_Area == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_52"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q5-2'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(3)自來水、電力管線及瓦斯內管費是否由建商負擔</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox :disabled="disabled" value="1" v-model="form.Is_BuilderBurden" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="0" v-model="form.Is_BuilderBurden" unchecked-value="">否</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Is_BuilderBurden == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_53"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q5-3'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(4)交屋保留款是否為房地總價5%以上</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" :disabled="disabled" v-model="form.Is_PremisesTotal" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" :disabled="disabled" v-model="form.Is_PremisesTotal" unchecked-value="">否</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Is_PremisesTotal == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_54"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q5-4'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(5)房屋的保固期限是否從交屋日起算，結構部分保固是否為15年以上</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox :disabled="disabled" value="1" v-model="form.Is_handoverDate" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="0" v-model="form.Is_handoverDate" unchecked-value="">否</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Is_handoverDate == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_55"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q5-5'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(6)面積誤差不足部分建商是否全部找補</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox :disabled="disabled" value="1" v-model="form.ErrorArea" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="0" v-model="form.ErrorArea" unchecked-value="">否</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.ErrorArea == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_56"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q5-6'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(7)是否有提供履約擔保機制</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox :disabled="disabled" value="1" v-model="form.Supply_Guarantee" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="0" v-model="form.Supply_Guarantee" unchecked-value="">否</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Supply_Guarantee == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_57"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q5-7'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(8)是否提供履約擔保證明文件影本</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox :disabled="disabled" value="1" v-model="form.Guarantee_Document" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="0" v-model="form.Guarantee_Document" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="2" v-model="form.Guarantee_Document" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Guarantee_Document == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_58"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q5-8'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(9)是否列明開工及取得使用執照明確日期（含完成主建物、附屬建物及使用執照所定之必要設施），及賣方逾期未開工或未取得使用執照之遲延利息為每逾1日按已繳房地價款萬分之五單利計算</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox :disabled="disabled" value="1" v-model="form.Has_LicenseDate" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="0" v-model="form.Has_LicenseDate" unchecked-value="">否</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Has_LicenseDate == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_59"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q5-9'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(10)是否約定因賣方違約致解除買賣契約，賣方應同時賠償予買方之違約金不低於房地總價款15%</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox :disabled="disabled" value="1" v-model="form.Has_indemnify" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox :disabled="disabled" value="0" v-model="form.Has_indemnify" unchecked-value="">否</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.Has_indemnify == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_510"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q5-10'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="remark-group">
        <div class="remark-title">備註</div>
        <div class="remark-body">
          <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="2">
            <b-col class="pb-4 pb-md-0">
              <b-form inline>
                <label>履約擔保機制種類：</label>
                <b-form-select v-model="form.P_ID" :options="Performancelist"></b-form-select>
              </b-form>
            </b-col>
          </b-row>
          <small>*本表僅列現場查核項目，其餘項目由地政單位攜回契約查核。</small>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import UploadImg from '@/components/UploadImg.vue'
export default {
  components: {
    UploadImg
  },
  data() {
    return {
      pageName: '預售屋買賣契約書訂定情形',
      formdata: [],
      page: "",
      Performancelist: [],
      disabled: false,
      selected: null,
      selections:['Supply_Review','Listed_Area','Is_BuilderBurden','Is_PremisesTotal','Is_handoverDate','ErrorArea','Supply_Guarantee','Guarantee_Document','Has_LicenseDate','Has_indemnify'],
      apiGetPerformance: (data) =>
        this.userRequest.post("API/Default/GetPerformance", data),
    };
  },
  computed:{
    form: function(){
        return this.$store.state.form
    },
    dateTime: function(){
        return this.$store.state.dateTime
    },
    OtherText: function(){
        return this.$store.state.OtherText
    },
  },
  watch:{
    "form.Is_recorded":function(val){
      if(val != ""){
        this.disabled = true;
        this.selections.forEach((element) => {
          this.$store.commit('increment',{key: element ,value :""})
        });
      }else{
        this.disabled = false;
      }
    },
    "form.Supply_Review":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_51" ,value :""})
      }
    },
    "form.Listed_Area":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_52" ,value :""})
      }
    },
    "form.Is_BuilderBurden":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_53" ,value :""})
      }
    },
    "form.Is_PremisesTotal":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_54" ,value :""})
      }
    },
    "form.Is_handoverDate":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_55" ,value :""})
      }
    },
    "form.ErrorArea":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_56" ,value :""})
      }
    },
    "form.Supply_Guarantee":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_57" ,value :""})
      }
    },
    "form.Guarantee_Document":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_58" ,value :""})
      }
    },
    "form.Has_LicenseDate":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_59" ,value :""})
      }
    },
    "form.Has_indemnify":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_510" ,value :""})
      }
    },
  },
  created() {
    this.userSignCheck();
    
    if(this.IsNullEmpty(this.form.Is_recorded)){
      this.disabled = false;
      console.log("form.Is_recorded",this.form.Is_recorded,this.disabled);
    }
    else{
      this.disabled = true;
    }
    this.FunctionToken(this.GetPerformance, {});
  },
  mounted() { },
  methods: {
    formatter(e){
      return String(e).substring(0,150);
    },
    GetPerformance(data) {
      this.apiGetPerformance(data)
        .then((res) => {
          const json = res.data;
          //console.log("Performancejson:", json);
          const jsondata = JSON.parse(json.Data);
          this.Performancelist = [];
          //this.formdata.P_ID = jsondata[0].PG_ID;
          if(this.form.P_ID == null && this.form.P_ID ==""){
            this.$store.commit("increment",{key: 'P_ID' ,value :jsondata[0].PG_ID});
          }
          for (var index in jsondata) {
            this.Performancelist.push({ value: jsondata[index].PG_ID, text: jsondata[index].PG_content });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
