<template>
  <div class="innerPageMainContent">
    <b-card>
      <div class="topicGroup">
        <div class="topicItem">
          <h4>參、查核項目</h4>
          <div class="topicSubItemtitle">
            <b-row>
              <b-col cols="6">5.預售屋契約轉讓情形（112.7.1之後）</b-col>
              <b-col cols="6" class="text-success">
                檢查結果
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(1)是否同意買受人將契約違規轉讓與第三人</li>
                </ul>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.NotAgreeTransfer" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.NotAgreeTransfer" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.NotAgreeTransfer" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.NotAgreeTransfer == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_61"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q6-1'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(2)是否協助買受人將契約違規轉讓與第三人</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.NotAssistTransfer" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.NotAssistTransfer" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.NotAssistTransfer" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.NotAssistTransfer == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_62"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q6-2'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="2">
              <b-col>
                <ul>
                  <li>(3)是否接受買受人委託違規刊登契約轉讓廣告</li>
                </ul>
              </b-col>
              <b-col>
                <b-row>
                  <b-col >
                    <b-form inline>
                      <b-form-checkbox value="1" v-model="form.NotAgreeTransferAD" unchecked-value="">是</b-form-checkbox>
                      <b-form-checkbox value="0" v-model="form.NotAgreeTransferAD" unchecked-value="">否</b-form-checkbox>
                      <b-form-checkbox value="2" v-model="form.NotAgreeTransferAD" unchecked-value="">待查明</b-form-checkbox>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-if="form.NotAgreeTransferAD == '0'">
                  <b-col>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" maxlength="30" v-model="OtherText.other_63"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <UploadImg :topic="'Q6-3'"></UploadImg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="remark-group">
        <div class="remark-title">備註</div>
        <div class="remark-body">
          <b-col cols="12" class="pb-2">
            <b-form inline><label class="mr-sm-2">112.7.1後契約轉讓/變更案件：</label></b-form>
            <b-form inline>
              <label class="mr-sm-2">1.施行前已簽約或依法繼受</label>
              <b-form-input class="mb-0" :readonly="Datekeyin" :formatter="formatter_1"  v-model="form.Signed"></b-form-input>
              <label class="mx-sm-2">件</label>
            </b-form>
            <b-form inline>
              <label class="mr-sm-2">2.配偶、直系血親或二親等內旁系血親間之轉讓</label>
              <b-form-input class="mb-0" :readonly="Datekeyin" :formatter="formatter_1"  v-model="form.Transfer"></b-form-input>
              <label class="mx-sm-2">件</label>
            </b-form>
            <b-form inline>
              <label class="mr-sm-2">3.主管機關核准轉讓</label>
              <b-form-input class="mb-0" :readonly="Datekeyin" :formatter="formatter_1"  v-model="form.ApproveTransfer"></b-form-input>
              <label class="mx-sm-2">件</label>
            </b-form>
            <b-form inline>
              <label class="mr-sm-2">4.其他(繼承或法人合併等)</label>
              <b-form-input class="mb-0" :readonly="Datekeyin" :formatter="formatter_1"  v-model="form.Other2"></b-form-input>
              <label class="mx-sm-2">件</label>
            </b-form>
          </b-col>
          <small>註：本項應請業者提供辦理轉讓(變更)案件相關資料影本。</small>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import UploadImg from '@/components/UploadImg.vue'
export default {
  components: {
    UploadImg
  },
  data() {
    return {
      pageName: '預售屋契約轉讓情形',
      Datekeyin:false,
    };
  },
  watch:{
    "form.NotAgreeTransfer":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_61" ,value :""})
      }
    },
    "form.NotAssistTransfer":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_62" ,value :""})
      }
    },
    "form.NotAgreeTransferAD":function(val){
      if(val != '0'){
        this.$store.commit('incrementOtherText',{key: "other_63" ,value :""})
      }
    },
    
  },
  created() {
    this.userSignCheck();
  },
  computed:{
    form: function(){
        return this.$store.state.form
    },
    dateTime: function(){
        return this.$store.state.dateTime
    },
    OtherText: function(){
        return this.$store.state.OtherText
    },
  },
  mounted() { },
  methods: {
    formatter(e){
      return String(e).substring(0,150);
    },
    formatter_1(e){
      return String(e).substring(0,15);
    },
  },
};
</script>
