<template>
  <div class="Login">
    <b-container>
      <b-row class="LoginWrap" cols="1" cols-sm="1" cols-md="2" cols-lg="3" cols-xl="3" align-h="center" align-v="center">
        <b-col>
          <b-card>
            <b-form class="p-1">
              <b-form-group label="帳號" label-for="username" class="text-left">
                <b-form-input
                  id="username"
                  type="text"
                  placeholder="請輸入帳號"
                  v-model="loginData.Id"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="密碼" label-for="password" class="text-left">
                <b-form-input
                  id="password"
                  type="password"
                  placeholder="請輸入密碼"
                  v-model="loginData.DecryptionPass"
                ></b-form-input>
              </b-form-group>
              <b-row
                ><b-col>
                  <b-form-group
                    label="圖形驗證碼"
                    label-for="code"
                    class="text-left"
                  >
                    <b-form-input
                      id="code"
                      type="text"
                      placeholder="請輸入圖形驗證碼"
                      v-model="loginData.CodePass"
                      autocomplete="off"
                      required
                    ></b-form-input> </b-form-group></b-col
                ><b-col> <b-img fluid :src="imageCode" class="mb-3" style="margin-top: 25px;"/></b-col
              ></b-row>
              <b-form-group>
                <b-link>忘記密碼</b-link>
              </b-form-group>

              <b-button size="lg" @click="userLogin" block variant="primary"
                >登入</b-button
              >

            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>
<script>
  export default {
    name:"login",
    data() {
      return {
        imageCode: "",
        navigationActive: [],
        isLoading: false,
        items: [
          {
            text: "首頁",
            href: "#/",
          },
          {
            text: "登入",
            active: true,
          },
        ],
        loginData: {
          Id: "",
          DecryptionPass: "",
          CodePass: "",
          DecryptionCodePass: "",
          Type: "login",
          TokenID: "",
          Token: "",
          Page: "",
        },
        lastpass: "",
        checkA: false,
        errormsg: "",
        apiGetImageCode: (data) =>
          this.userRequest.post("API/Default/GetImageCode", data),
        apiLogin: (data) =>
          this.userRequest.post("API/Default/Login", data),
      };
    },
    created() {
      sessionStorage.removeItem("TokenID");
      sessionStorage.removeItem("alertCache");
      sessionStorage.removeItem("enterCache");
      sessionStorage.removeItem("formdata");
      this.getImageCode();
    },
    mounted() {
      // this.init();
    },
    methods: {
      init() {
        
      },
      userLogin() {
        this.checkLoginData();
        if (this.errormsg === "") {
          this.FunctionToken(this.Login, this.loginData);
        }
      },
      //-----------api----------------
      getImageCode() {
        this.apiGetImageCode()
          .then((res) => {
            const json = JSON.parse(res.data);
            if (json.Status) {
              const jsonImage = JSON.parse(json.Data);
              this.imageCode = jsonImage.Image;
              this.loginData.DecryptionCodePass = jsonImage.Code;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      checkLogin(data) {
        this.apiLogin(data)
          .then((res) => {
            const json = JSON.parse(res.data);
            if (json.Status) {
              this._go("/backend/AdminNews");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      Login(data) {
        this.apiLogin(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            this.getImageCode();
            if (json.Status) {
              const jsonToken = JSON.parse(json.Data);
            console.log("jsonToken",jsonToken);
            sessionStorage.setItem("TokenID", jsonToken);
            this.TokenID =sessionStorage.getItem("TokenID");
            if (this.checkA) {
              sessionStorage.setItem("CCC", data.Id);
            } else {
              sessionStorage.setItem("CCC", null);
            }
            sessionStorage.setItem("PM_TYPE", 0);
            //this.$router.push({ path: "/Home" });
            this.$router.push({ path: "/Home" });
            } else {
            alert(json.Message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      checkLoginData() {
      this.errormsg = "";
      if (this.loginData.Id == "") this.errormsg += "\n請輸入帳號";
      if (this.loginData.DecryptionPass == "")
        this.errormsg += "\n請輸入密碼!!";
      if (this.loginData.CodePass == "")
        this.errormsg += "\n請輸入正確的圖形驗證碼!!";
      if (this.errormsg != "") alert(this.errormsg);
      },
    },
  };
</script>
