import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        form: {
            Form_ID:null
        },
        dateTime:{
            FoundDate:null
        },
        OtherText:{
            O_Form_ID:null,
            other_21:null,
            other_22:null,
            other_31:null,
            other_32:null,
            other_33:null,
            other_34:null,
            other_35:null,
            other_36:null,
            other_37:null,
            other_41:null,
            other_42:null,
            other_43:null,
            other_44:null,
            other_45:null,
            other_46:null,
            other_47:null,
            other_48:null,
            other_49:null,
            other_410:null,
            other_51:null,
            other_52:null,
            other_53:null,
            other_54:null,
            other_55:null,
            other_56:null,
            other_57:null,
            other_58:null,
            other_59:null,
            other_510:null,
            other_61:null,
            other_62:null,
            other_63:null,
            other_71:null,
            other_72:null,
            other_73:null,
            other_74:null,
            other_75:null,
            other_76:null,
            other_81:null,
            other_82:null,
            other_83:null,
            other_84:null,
            other_91:null,
            other_92:null,
            other_93:null,
        },
        InspectorRecord:[
            {
                id: 1,
                Inspector_NAME: "",
                esign: "",
            }
        ],
        InspectorRecord2:[
            {
                id: 1,
                Inspector_NAME: "",
                esign: "",
            }
        ],
        Salesperson: [
            {
                Sort: "1",
                Form_ID: "",
                NAME: "",
                ID_number: "",
                Broker_CN: "",
                Broker_Remark: "",
                Sales_CN: "",
                Sales_Remark: "",
                Remark: "",
            }
        ],
        ImgFile:[]
    },
    mutations: {
        increment(state,payload){
            //state.test.id = payload.id;
            // for(const [key,value] of Object.entries(payload)){
            //     state.test[key] = value;
            // }
            state.form[payload.key] = payload.value;
            
        },
        incrementDateTime(state,payload){
            //console.log('payload',payload);
            state.dateTime[payload.key] = payload.value;
            //console.log('state.dateTime',state.dateTime);
        },
        incrementOtherText(state,payload){
            state.OtherText[payload.key] = payload.value;
        },
        incrementInspectorRecord(state,payload){
            state.InspectorRecord[payload.index][payload.key] = payload.value;
        },
        incrementInspectorRecord2(state,payload){
            state.InspectorRecord2[payload.index][payload.key] = payload.value;
        },
        incrementSalesperson(state,payload){
            state.Salesperson[payload.index][payload.key] = payload.value;
        },
        incrementImgFile(state,payload){
            state.ImgFile = payload;
        },
        updateDateTime(state,payload){
            state.dateTime = {};
            state.dateTime = payload;
        },
        updateState(state,payload){
            state.form = {};
            state.form = payload;
        },
        updateOtherText(state,payload){
            state.OtherText = {};
            state.OtherText = payload;
        },
        updateInspectorRecord(state,payload){
            state.InspectorRecord = [];
            state.InspectorRecord = payload;
        },
        updateInspectorRecord2(state,payload){
            state.InspectorRecord2 = [];
            state.InspectorRecord2 = payload;
        },
        updateSalesperson(state,payload){
            state.Salesperson = [];
            state.Salesperson = payload;
        },
        resetState (state){
            state.form = {};
        },
        resetDateTime (state){
            state.dateTime = {
                FoundDate: null,
                ObtainLicenseDate: null,
                ReportDate: null,
                CensorDate: null,
                SigningDate: null,
                DForSale_Date: null,
                ConsignmentDate_begin: null,
                ConsignmentDate_until: null,
                Alloted_Time: null
            };
        },
        resetOtherText (state){
            state.OtherText = {
                O_Form_ID:null,
                other_21:null,
                other_22:null,
                other_31:null,
                other_32:null,
                other_33:null,
                other_34:null,
                other_35:null,
                other_36:null,
                other_37:null,
                other_41:null,
                other_42:null,
                other_43:null,
                other_44:null,
                other_45:null,
                other_46:null,
                other_47:null,
                other_48:null,
                other_49:null,
                other_410:null,
                other_51:null,
                other_52:null,
                other_53:null,
                other_54:null,
                other_55:null,
                other_56:null,
                other_57:null,
                other_58:null,
                other_59:null,
                other_510:null,
                other_61:null,
                other_62:null,
                other_63:null,
                other_71:null,
                other_72:null,
                other_73:null,
                other_74:null,
                other_75:null,
                other_76:null,
                other_81:null,
                other_82:null,
                other_83:null,
                other_84:null,
                other_91:null,
                other_92:null,
                other_93:null,
            };
        },
        resetInspectorRecord (state){
            state.InspectorRecord = [
                {
                    id: 1,
                    Inspector_NAME: "",
                    esign: "",
                }
            ];
        },
        resetInspectorRecord2 (state){
            state.InspectorRecord2 = [
                {
                    id: 1,
                    Inspector_NAME: "",
                    esign: "",
                }
            ];
        },
        resetSalesperson (state){
            state.Salesperson = [
                {
                    Sort: "1",
                    Form_ID: "",
                    NAME: "",
                    ID_number: "",
                    Broker_CN: "",
                    Broker_Remark: "",
                    Sales_CN: "",
                    Sales_Remark: "",
                    Remark: "",
                }
            ];
        },
        resetImgFile (state){
            state.ImgFile = [];
        },
    }
})
