<template>
  <div class="innerPageMainContent">
    <b-card>
      <div class="topicGroup">
        <div class="topicItem">
          <h4>參、查核項目</h4>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="1">
              <b-col>
                11.補正事項：
                <b-form inline>
                  <label class="mr-sm-2">請業者於</label>
                    <b-input-group class="mb-2 dateInput">
                      <b-form-input
                        v-model="dateTime.Alloted_Time"
                        type="text"
                        placeholder="YYYMMDD，例如:0991231"
                        autocomplete="off"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="form.Alloted_Time"
                          button-only
                          right
                          dropright
                          locale="zh-TW"
                          aria-controls="example-input"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  <label class="mx-sm-2">前補齊下列文件：</label>
                </b-form>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="1">
              <b-col>
                <b-form-checkbox id="checkbox-1" v-model="form.Corrections_Other" name="checkbox-1" value="1" unchecked-value="">
                  銷售建案、銷售中心、樣品屋之建造執照或建築許可影本各1份
                </b-form-checkbox>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="1">
              <b-col>
                <b-form-checkbox id="checkbox-2" v-model="form.StubLink" name="checkbox-2" value="1" unchecked-value="">
                  已簽訂之購屋預約單存根聯影本（首次簽訂1份、指定14戶別各1份）
                </b-form-checkbox>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="1">
              <b-col>
                <b-form-checkbox id="checkbox-3" v-model="form.AD_HouseDocument" name="checkbox-3" value="1" unchecked-value="">
                  銷售廣告文件、房屋平面配置參考圖
                </b-form-checkbox>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="1">
              <b-col>
                <b-form-checkbox id="checkbox-4" v-model="form.SharingInformation" name="checkbox-4"
                  value="1" unchecked-value="">
                  全區各戶建物各項面積及共有部分之分攤比例資訊
                </b-form-checkbox>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="1">
              <b-col>
                <b-form-checkbox id="checkbox-5" v-model="form.SpacePlan" name="checkbox-5" value="1" unchecked-value="">
                  基地位置圖/地盤圖/各層平面圖及停車空間平面圖
                </b-form-checkbox>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="1">
              <b-col>
                <b-form-checkbox id="checkbox-6" v-model="form.HouseSales_Contract" name="checkbox-6"
                  value="1" unchecked-value="">
                  最近已簽約之預售屋買賣契約書影本至少5份（首次簽訂1份、指定4戶各1份，請切結與正本相符，如有不實願負法律責任）
                </b-form-checkbox>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="1">
              <b-col>
                <b-form-checkbox id="checkbox-7" v-model="form.Performance_CD" name="checkbox-7" value="1" unchecked-value="">
                  預售屋履約擔保證明文件影本
                </b-form-checkbox>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="1">
              <b-col>
                <b-form-checkbox id="checkbox-8" v-model="form.DataCopy" name="checkbox-8" value="1" unchecked-value="">
                  112.7.1後全部之「契約轉讓或變更案件」數量及相關資料影本
                </b-form-checkbox>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="1">
              <b-col>
                <b-form-checkbox id="checkbox-9" v-model="form.Broker_CD" name="checkbox-9" value="1" unchecked-value="">
                  代銷業許可、公會會員證書、經紀人證書影本、現場代銷經紀人員名冊及資格證明文件
                </b-form-checkbox>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="1">
              <b-col>
                <b-form-checkbox id="checkbox-10" v-model="form.Consignment" name="checkbox-10"
                  value="1" unchecked-value="">
                  委託代銷契約書影本
                </b-form-checkbox>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="1">
              <b-col>
                <b-form-checkbox id="checkbox-11" v-model="form.Construction" name="checkbox-11"
                  value="1" unchecked-value="">
                  建案銷控表
                </b-form-checkbox>
              </b-col>
            </b-row>
          </div>
          <div class="topicSubItemList">
            <b-row align-v="center" cols="1">
              <b-col>
                <b-form inline>
                  <b-form-checkbox id="checkbox-12" v-model="form.Other10" name="checkbox-12"
                    value="1" unchecked-value="0">
                    其他
                  </b-form-checkbox>
                  <b-form-input id="inline-form-input-name" class="mb-2 mr-sm-2 mb-sm-0" placeholder="請輸入其他"
                    :disabled="disabled" v-model="form.C_Other_Directions" :formatter="formatter" maxlength="30"></b-form-input>
                </b-form>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import imgUrl from '../assets/imgs/contact_img.png'

export default {
  data() {
    return {
      disabled:true,
      Mdisabled:true,
      inspectorModal: {},
      deleteModalShow: false,
      pageName: '業者陳述意見及查核結果',
      padWidth: window.innerWidth *0.975,
      padhength: window.innerWidth *0.5,
      imgUrl,
      count: 1,
      page: "",
      selectedList: [],
      show_agencysale: true,
      InspectorRecord: [{
        id: 1,
        Inspector_NAME: "",
        esign: "",
      }],
      InspectorRecord2: [{
        id: 1,
        Inspector_NAME: "",
        esign: "",
      }],
      SignType:"Declarant",
      InspectorRecordindex: -1,
      InspectorRecord2index: -1,
      imgSrc: "",
      //canvas宣告區塊
      lineWidth: 4,
      lineColor: "#000000",
      bgColor: "#ffffff",
      resultImg: "",
      isCrop: true,
      //canvas宣告結束
      esing1item: [],
      selected: null,
      options: [
        { value: '內政部', text: '內政部' },
        { value: '公平交易委員會', text: '公平交易委員會' },
        { value: '行政院消保處', text: '行政院消保處' },
        { value: '財政部賦稅署所屬機關', text: '財政部賦稅署所屬機關' },
        { value: '地政局', text: '地政局' },
        { value: '消保官', text: '消保官' },
        { value: '都發局', text: '都發局' },
        { value: '住宅處', text: '住宅處' }
      ],
    };
  },
  computed:{
    form: function(){
        return this.$store.state.form
    },
    dateTime: function(){
        return this.$store.state.dateTime
    },
    OtherText: function(){
        return this.$store.state.OtherText
    },
  },
  created() {
    this.userSignCheck();
    if(this.form.Other10 != "1" ) {
      this.disabled=true;
    }
    else {
      this.disabled = false;
    }
  },
  watch:{
    "form.Other10"(val){
      if(val == "0") {
        this.disabled=true;
        this.$store.commit('increment',{key: "C_Other_Directions" ,value :""})
      }
      else {
        this.disabled = false;
      }
    },
    "form.Alloted_Time":function(val){
      //console.log("ObtainLicenseDate",val,this.date2str2(new Date(val)))
      if(this.date2str2(new Date(val)) != this.dateTime.Alloted_Time){
        this.$store.commit('incrementDateTime',{key: "Alloted_Time" ,value :!this.IsNullEmpty(val) ? this.date2str2(new Date(val)):""})
      }
    },
    "dateTime.Alloted_Time":function(val){
      //console.log("dateTime.Alloted_Time",val)
      if(val.length == 7 && val != this.date2str2(new Date(this.form.Alloted_Time)) ){
        let date = this.str2dateStr(val)
        if(this.IsDateValid(date)){
          this.$store.commit('increment',{key: "Alloted_Time" ,value :date})
          //this.$store.commit('incrementDateTime',{key: "FoundDate" ,value :date})
        }
        else{
          alert("日期格式錯誤")
        }
      }
      if(val == "" || val == null){
        this.$store.commit('increment',{key: "Alloted_Time" ,value :""})
      }
    }
  },  
  mounted() {
    
  },
  methods: {
    formatter_year(e){
      return String(e).substring(0,4);
    },
    formatter_key(e){
      return String(e).substring(0,20);
    },
    formatter(e){
      return String(e).substring(0,30);
    },
    getModalWidth() {
      this.$nextTick(() => {
        const modalWidth = this.$refs.modalRef.$el.querySelector('.modal-dialog').offsetWidth;
        console.log(modalWidth);
      });
    },
    //Canvas
    handleReset() {
      this.$refs.esign.reset();
    },
    InspectorSing(){
      this.SignType = "Inspector";
      this.imgSrc = "";
      this.imgSrc = this.form.InspectorSign;
      if(this.padWidth > 1024)this.$bvModal.show("modal-1");
      else this.$bvModal.show("modal-2");
    },
    AccompanyingSign(){
      this.SignType = "Accompanying";
      this.imgSrc = "";
      this.imgSrc = this.form.AccompanyingSign;
      if(this.padWidth > 1024)this.$bvModal.show("modal-1");
      else this.$bvModal.show("modal-2");
    },
    handleGenerate() {
      this.$refs.esign.generate({ format: 'image/jpeg', quality: 0.7 }).then(res => {
        this.resultImg = res;
        if (this.SignType == "Inspector" ) {
          this.$store.commit('increment',{key: "InspectorSign" ,value :res});
          this.imgSrc = res;
        }
        else if (this.SignType == "Accompanying") {
          this.$store.commit('increment',{key: "AccompanyingSign" ,value :res});
          this.imgSrc = res;
        }
        else {
          this.$store.commit('increment',{key: "Declarant" ,value :res});
          //this.form.Declarant = res;
          this.imgSrc = res;
        }
      }).catch(err => {
        alert(err)
      })
    },
    closesignmodal() {
      this.InspectorRecordindex = -1;
      this.InspectorRecord2index = -1;
    },
    Declarantimg() {
      this.SignType ="";
      this.imgSrc = "";
      this.imgSrc = this.form.Declarant;
      if(this.padWidth > 1024)this.$bvModal.show("modal-1");
      else this.$bvModal.show("modal-2");
    },
  },
};
</script>
<style lang="scss">
@media screen and (orientation: landscape) {
  #sign {
    padding: 0.1rem 1rem;
  }
  

}
#modal-2{
  padding: 0 !important;
}
.modal-body{
  padding: 0 !important;
}
.modal-fullscreen .modal-dialog{
  max-width: 100%;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  min-height: 100%;
  height: auto;
  margin: 0;
}
#imgSrc {
  height: 300px;
  width: 100%;
}
</style>

