<template>
    <div>
        <button @click="showModal"><b-img src="../assets/imgs/upload.png" class="icon" /></button>
        
        <b-modal ref="imgModal" hide-footer>
            <div class="imgModaldiv">
                <div class="d-block text-center">
                    <label class="btn btn-info">
                        <input style="display:none;" type="file" @change="onFileChange">
                        <i class="fa fa-photo"></i> 上傳圖片
                    </label>
                    
                </div>
                <div class="d-block text-center">
                    <img :src="imageUrl" alt="" style="max-width: 300px; max-height: 300px;" />
                </div>
                <div class="btn-div" >
                    <b-button class="btn mt-3 uploadbtn" variant="primary" @click="uploadImage" >上傳</b-button>
                </div>
                <div class="btn-div" >
                    <b-button class="btn mt-3 uploadbtn" @click="removeFile" v-if="imageUrl != null">移除附件</b-button>
                </div>
            </div>
            
        </b-modal>
    </div>
</template>
<script>
import uploadIcon from '../assets/imgs/upload.png'
export default{
    props:{
        topic: String,
    },
    data(){
        return{
            selectedFile: null,
            imageUrl: null,
            path:null,
        apiUploadImg: (data) =>
            this.userRequest.post("API/Default/UploadImg", data),
        apiImgFile: (data) =>
            this.userRequest.post("API/Default/ImgFileApi", data),
        }
    },
    computed:{
        ImgFile: function(){
            return this.$store.state.ImgFile
        },
    },
    created() {
        
    },
    methods:{
        showModal(){
            console.log("showModal",this.topic);
            if(this.ImgFile.length>0){
                let img = this.ImgFile.find(x=>x.Sort == this.topic);
                console.log(img);
                if(img){
                    this.imageUrl = img.ImgUri;
                    this.path = img.ImgPath;
                }
                else{
                    this.imageUrl = null;
                    this.path = null;
                }
            }
            this.$refs['imgModal'].show();
        },
        closeModal(){
            this.$refs['imgModal'].hide();
        },
        updateImgList(){
            this.FunctionToken(this.initImgFile,{TYPE:"LIST",Form_ID:this.$route.query.Id});
        },
        initImgFile(data){
        this.apiImgFile(data)
            .then((res) =>{
                const json = res.data;
                //console.log("ImgFile",json);
                if(json.Status && json.Data != "null"){
                    const jsondata = JSON.parse(json.Data);
                    console.log("ImgFile",jsondata);
                    if(jsondata.length > 0){
                        this.$store.commit('incrementImgFile',jsondata);
                    }
                }
            })
        },
        onFileChange(event) {
            console.log(this.topic)
            this.selectedFile = event.target.files[0];
            console.log(this.selectedFile)
            if (this.selectedFile) {
                this.imageUrl = URL.createObjectURL(this.selectedFile);
            } else {
                this.imageUrl = null; // 清空圖片
            }
        },
        uploadImage(){
            if (!this.selectedFile) {
                alert("請選擇檔案");
                return;
            }

            const formData = new FormData();
            formData.append("file", this.selectedFile);
            this.apiUploadImg(formData).then((res) => {
                //console.log("uploadImage",json);
                const json =  JSON.parse(res.data);
                console.log("uploadImage",json);
                if (json.Status) {
                    let formId = this.$route.query.Id;
                    const jsonData= JSON.parse(json.Data);
                    console.log(jsonData);
                    let obj={
                        FormID: formId,
                        ImgUri: jsonData.url,
                        ImgPath: jsonData.path,
                        Filename: jsonData.filename,
                        Sort: this.topic,
                    }
                    this.FunctionToken(this.upload,{TYPE:"INSERT",ImgFile:obj})
                }
            });
        },
        upload(data){
            this.apiImgFile(data).then((res) => {
                //console.log("apiImgFile",res);
                const json = res.data;
                //console.log("apiImgFile",json);
                if (json.Status) {
                    //alert("上傳成功");
                    this.closeModal();
                }
            });
        },
        removeFile(){
            this.selectedFile = null;
            this.imageUrl = null;
            
            if(this.path != null){
                let obj={
                    FormID: this.$route.query.Id,
                    Sort: this.topic,
                }
                this.FunctionToken(this.remove,{TYPE:"DELETE",ImgFile:obj})
            }
            
        },
        remove(data){
            this.apiImgFile(data).then((res) => {
                const json = res.data;
                console.log(json);
                if (json.Status) {
                    //alert("移除成功");
                    //this.closeModal();
                    this.path = null;
                }
            });
        }
    }
}
</script>
<style scoped>
.imgModaldiv{
    padding-top: 10px;
    padding-bottom: 10px;
}
.btn-div{
    display: flex;
    justify-content: center;
}
.uploadbtn{
    width: 250px;
}
.icon{
    width: 40px;
    height: 40px;
}
</style>