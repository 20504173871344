import Vue from "vue";
import VueRouter from "vue-router";
//前台
import Home from '../views/Home';
import FormData from '../views/FormData.vue'
import Check from '../views/Check';
import login from '../views/login';
import userBar from '../components/userBar.vue';


import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

//防止路由重複讀取錯誤
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const scrollBehavior = function (to, from, savedPosition) {
  // savedPosition 会在你使用浏览器前进或后退按钮时候生效
  // 这个跟你使用 router.go() 或 router.back() 效果一致
  // 这也是为什么我在 tab 栏结构中放入了一个 点击回退 的按钮
  if (savedPosition) {
      return savedPosition
  } else {
      // 如果不是通过上述行为切换组件，就会让页面回到顶部
      return { x: 0, y: 0 }
  }
}
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: '/login'
    
  },
  {
    path: "/login",
    component: userBar,
    children: [
      {
        path: "/",
        name: "login",
        component: login,
      },
    ],
  },
  {
    path: "/Home",
    component: userBar,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/FormData",
        name: "FormData",
        component: FormData,
      },
      {
        path: "/Check",
        name: "Check",
        component: Check,
      },
    ]
  },
];


const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes,
});

export default router;