<template>
  <div class="innerPage">
    <div class="innerPageHead">
      <b-container>
        <b-breadcrumb :items="items"></b-breadcrumb>
      </b-container>
    </div>
    <div class="innerPageContent">
      <b-container>
        <div class="innerPageContentTitle">
          <b-row align-h="between" class="pb-4">
            <b-col cols="auto">
              <h3>{{ formdata.BuildCase }}</h3>
            </b-col>
            <b-col cols="auto">
              <b-button variant="info" class="mx-1" @click="gotoupdate"><b-icon icon="pencil-square"
                  class="mr-1"></b-icon>編輯</b-button>
              <b-button variant="outline-danger" class="mx-1" @click="printPDF"><b-icon icon="printer-fill"
                  class="mr-1"></b-icon>PDF列印</b-button>
              <!-- <a href="http://192.168.7.137:8081/uplode/%e9%a0%90%e5%94%ae%e5%b1%8b%e8%81%af%e5%90%88%e7%a8%bd%e6%9f%a5%e7%b4%80%e9%8c%84%e8%a1%a8.pdf"
                target="_blank" download>
                <b-button variant="success">PDF下載</b-button>
              </a> -->
              <a :href="docxurl"
                target="_blank" download>
                <b-button variant="success">PDF下載</b-button>
              </a>
            </b-col>
          </b-row>
          <b-row align-h="between">
            <b-col cols="auto" class="pb-2">
              <b-form inline>
                <label class="mr-2">查核時間:</label>
                <span>{{ formdata.IDate }}</span>
              </b-form>
            </b-col>
            <b-col cols="auto" class="pb-2">
              <b-form inline>
                <label class="mr-2">編號:</label>
                <b-col cols="auto" class="pb-2">
                  {{ formdata.Number_Year }}
                </b-col>
                <!-- <span>{{ formdata.Number_Year }}</span> -->
                <label class="mr-2">年度第</label>
                <b-col cols="auto" class="pb-2">
                  {{ formdata.Number_Key }}
                </b-col>
                <!-- <span>{{ formdata.Number_Key }}</span> -->
                <label class="mr-2">號</label>
              </b-form>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col cols="auto" class="pb-2">
              承辦人員：{{ formdata.I_Name }}
            </b-col>
          </b-row> -->
        </div>
        <div class="innerPageMainContent">
          <template>
            <!-- <vue-office-docx
                id="printArea"
                :src="docxurl"
                style="height: 100vh;"
                @rendered="rendered"
            /> -->
            <!-- <b-embed
              type="iframe"
              aspect="1by1"
              :src="docxurl"
              style="border: 0;"              
            ></b-embed> -->
            <iframe v-if="!isIOS" :src="docxurl" :height="window.height" width="100%" style="border: 0;" >
              你的瀏覽器不支援 iframe
            </iframe>
            <p v-else>IOS 系統請使用「PDF下載」做預覽</p>
            <!-- <vue-office-pdf id="printArea" :src="docxurl" style="height: 100vh;" @rendered="rendered" /> -->
          </template>
        </div>
        <div class="btn-wrap">
          <b-row align-h="between">
            <b-col>
              <b-button @click="gotoback" block variant="outline-primary">返回列表</b-button></b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>
<script>
//import VueOfficeDocx from '@vue-office/docx'
//import VueOfficePdf from '@vue-office/pdf'
import '@vue-office/docx/lib/index.css'
import print from 'vue-print-nb'
import axios from "axios";
let docx = require("docx-preview");


export default {
  components: {
    //VueOfficeDocx,
    //VueOfficePdf,
  },
  data() {
    return {
      formdata: [],
      navigationActive: [],
      docxurl: "",
      isIOS:false,
      isMobile:false,
      window: {
        width: 1024,
        height: 0,
      },
      items: [
        {
          text: "首頁",
          href: "#/Home",
        },
        {
          text: "check",
          active: true,
        },
      ],
      selected: null,
      options: [
        { value: null, text: '112' },
        { value: 'a', text: '113' },
        { value: 'b', text: '114' },
        { value: 'c', text: '115' }
      ],
      apiGetCheckData: (data) =>
        this.userRequest.post("API/Default/GetCheckData", data),
      apiGetFormData: (data) =>
        this.userRequest.post("API/Default/GetFormData", data),
      apiSearchSalesperson: (data) =>
        this.userRequest.post("API/Default/SearchSalesperson", data),
      apiSearchInspectorRecord: (data) =>
        this.userRequest.post("API/Default/SearchInspectorRecord", data),
    };
  },
  computed:{
    form: function(){
        return this.$store.state.form
    }
},
  created() {
    this.userSignCheck();
    this.appSource();
    if (sessionStorage.getItem("CheckData") !== null) {
      const arr = sessionStorage.getItem("CheckData");
      const jsondata = JSON.parse(arr)
      //console.log(jsondata);
      this.formdata = [];
      this.formdata = jsondata;
      this.formdata.InspectDate = jsondata.InspectDate.split('T')[0];
    }
    var str = JSON.parse(sessionStorage.getItem("filepath"));
    this.handleResize();
    //console.log("str", str.Path);
    //this.docxurl="http://localhost:8080/uplode/%E9%A0%90%E5%94%AE%E5%B1%8B%E8%81%AF%E5%90%88%E7%A8%BD%E6%9F%A5%E7%B4%80%E9%8C%84%E8%A1%A820240221092939546.pdf";
    //this.docxurl = "http://192.168.7.137:8081/uplode/%e9%a0%90%e5%94%ae%e5%b1%8b%e8%81%af%e5%90%88%e7%a8%bd%e6%9f%a5%e7%b4%80%e9%8c%84%e8%a1%a8.pdf";
    //this.docxurl = "https://easymap.tw/tc_lab/uplode/%e9%a0%90%e5%94%ae%e5%b1%8b%e8%81%af%e5%90%88%e7%a8%bd%e6%9f%a5%e7%b4%80%e9%8c%84%e8%a1%a8.pdf";
    this.docxurl = str.Path;
    this._isMobile();
  },
  directives: {
    print
  },
  mounted() { },
  methods: {
    _isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      if(!flag){
        //alert("PC端");
        this.isMobile = false;
      }
      else{
        //alert("手機端");
        this.isMobile = true;
      }
    },
    appSource(){
      const u = navigator.userAgent;
      var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      let flag = navigator.userAgent.match(/(iPhone|iPod|ios|iPad)/i);
      if(isIos || flag){
        this.isIOS = true;
      }
      else this.isIOS=false;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight *0.6;
      //console.log("window.height",this.window.height);
    },
    printPDF() {
      //this.$print({ printable: 'http://192.168.7.137:8081/uplode/%e9%a0%90%e5%94%ae%e5%b1%8b%e8%81%af%e5%90%88%e7%a8%bd%e6%9f%a5%e7%b4%80%e9%8c%84%e8%a1%a8.pdf', type: 'pdf' })
      if(this.isMobile){
        window.location.href =this.docxurl;
      }
      else this.$print({ printable: this.docxurl, type: 'pdf' }); 
    },
    // userSignCheck() {
    //     if (sessionStorage.getItem("TokenID") === null) {
    //         alert("請登入系統!!");
    //         this.$router.push({ path: "/Login" });
    //     }
    // },
    gotoupdate() {
      this.$router.push({ path: "/FormData",query:{Id:this.formdata.Form_ID,index:1} });
    },
    update(data) {
      this.apiGetFormData(data)
        .then((res) => {
          const json = res.data;
          const jsondata = JSON.parse(json.Data);
          if (json.Status) {
            this.$store.commit('updateState',jsondata);
            //sessionStorage.setItem("formdata", json.Data);
            //console.log("update json", sessionStorage.getItem("formdata"));
            //this.$router.push({ path: "/page1" });
            this.$router.push({ path: "/FormData" });
          }
        })
        .catch((err) => {
          console.log(err);

        });
    },
    SearchSalesperson(data) {
      this.apiSearchSalesperson(data)
        .then((res) => {
          const json = res.data;
          if (json.Status) {
            if (json.Data != "null") {
              const jsondata = JSON.parse(json.Data);
              //console.log("salesperson:", jsondata);

              for (var index in jsondata) {
                let borkertemp = [];
                let salestemp = [];
                borkertemp.push(jsondata[index].Broker_Remark);
                salestemp.push(jsondata[index].Sales_Remark);
                //console.log("borkertemp、salestemp",borkertemp,salestemp);
                jsondata[index].Broker_Remark = [];
                jsondata[index].Sales_Remark = [];
                jsondata[index].Broker_Remark = borkertemp;
                jsondata[index].Sales_Remark = salestemp;
              }
              //console.log("jsondata:", jsondata);
              sessionStorage.setItem("Salesperson", JSON.stringify(jsondata));
            }
            //console.log("sessionStorage.getItem(Salesperson) != null", sessionStorage.getItem("Salesperson") != null);
          }
        })
        .catch((err) => {
          console.log(err);

        });
    },
    SearchInspectorRecord(data) {
      this.apiSearchInspectorRecord(data)
        .then((res) => {
          const json = res.data;
          const jsondata = JSON.parse(json.Data);
          if (json.Status) {
            let Inspectorlist = [];
            let Inspectorlist2 = [];
            //console.log("InspectorRecordList:", jsondata);
            for (var index in jsondata) {
              let obj = [];
              if (jsondata[index].Listnum == 1) {
                obj = {
                  id: jsondata[index].Inspect_Sort,
                  Inspector_NAME: jsondata[index].Inspect_Name,
                  esign: jsondata[index].E_signature,
                }
                Inspectorlist.push(obj);
              }
              else {
                obj = {
                  id: jsondata[index].Inspect_Sort,
                  Inspector_NAME: jsondata[index].Inspect_Name,
                  esign: jsondata[index].E_signature,
                }
                Inspectorlist2.push(obj);
              }
            }
            //console.log("Inspectorlist:", Inspectorlist);
            //console.log("Inspectorlist2", Inspectorlist2);
            if (Inspectorlist.length > 0) sessionStorage.setItem("InspectorRecord", JSON.stringify(Inspectorlist));
            if (Inspectorlist2.length > 0) sessionStorage.setItem("InspectorRecord2", JSON.stringify(Inspectorlist2));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gotoback() {
      this.$router.push({ path: "/Home" });
    },
    rendered() {
      console.log("渲染完成")
    },
    printDocument() {
      window.print();
    },
  },
};
</script>
